import { get } from 'services/api';

export const getMinimumPurchase = async () => {
  const response = await get('minimumAmount');
  return response;
};

export const getEnvioGratis = async () => {
  const response = await get('envio_gratis');
  return response;
};

export const getDiscount = async () => {
  const response = await get('discount');
  return response;
};

export const getAllSliders = async () => {
  const response = await get('sliders');
  return response;
};

export const getWhatsapp = async () => {
  const response = await get('whatsapp');
  return response;
};

export const getPopUpImage = async () => {
  const response = await get('/popup');
  return response;
};

export const getContact = async () => {
  const response = await get('/contact');
  return response;
};

export const getPdfTarifas = async () => {
  const response = await get('/tarifas');
  return response;
};

export const getEnvios = async () => {
  const response = await get('envios');
  return response;
};
