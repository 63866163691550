export const APP_INITIALIZE_REQUESTED = 'APP_INITIALIZE_REQUESTED';
export const APP_HOME_REQUESTED = 'APP_HOME_REQUESTED';

export const APP_DISCOUNT_REQUESTED = 'APP_DISCOUNT_REQUESTED';
export const APP_DISCOUNT_SUCCEEDED = 'APP_DISCOUNT_SUCCEEDED';
export const APP_DISCOUNT_FAILED = 'APP_DISCOUNT_FAILED';

export const APP_MINIMUM_PURCHASE_REQUESTED = 'APP_MINIMUM_PURCHASE_REQUESTED';
export const APP_MINIMUM_PURCHASE_SUCCEEDED = 'APP_MINIMUM_PURCHASE_SUCCEEDED';
export const APP_MINIMUM_PURCHASE_FAILED = 'APP_MINIMUM_PURCHASE_FAILED';

export const APP_ENVIO_GRATIS_REQUESTED = 'APP_ENVIO_GRATIS_REQUESTED';
export const APP_ENVIO_GRATIS_SUCCEEDED = 'APP_ENVIO_GRATIS_SUCCEEDED';
export const APP_ENVIO_GRATIS_FAILED = 'APP_ENVIO_GRATIS_FAILED';

export const APP_ENVIOS_REQUESTED = 'APP_ENVIOS_REQUESTED';
export const APP_ENVIOS_SUCCEEDED = 'APP_ENVIOS_SUCCEEDED';
export const APP_ENVIOS_FAILED = 'APP_ENVIOS_FAILED';

export const APP_SLIDERS_GET_ALL_REQUESTED = 'APP_SLIDERS_GET_ALL_REQUESTED';
export const APP_SLIDERS_GET_ALL_SUCCEEDED = 'APP_SLIDERS_GET_ALL_SUCCEEDED';
export const APP_SLIDERS_GET_ALL_FAILED = 'APP_SLIDERS_GET_ALL_FAILED';

export const APP_WHATSAPP_REQUESTED = 'APP_WHATSAPP_REQUESTED';
export const APP_WHATSAPP_SUCCEEDED = 'APP_WHATSAPP_SUCCEEDED';
export const APP_WHATSAPP_FAILED = 'APP_WHATSAPP_FAILED';
export const APP_IMAGE_POPUP_SUCCEEDED = 'APP_IMAGE_POPUP_SUCCEEDED';
export const APP_IMAGE_POPUP_FAILED = 'APP_IMAGE_POPUP_FAILED';

export const APP_TARIFAS_REQUESTED = 'APP_TARIFAS_REQUESTED';
export const APP_TARIFAS_SUCCEEDED = 'APP_TARIFAS_SUCCEEDED';
export const APP_TARIFAS_FAILED = 'APP_TARIFAS_FAILED';

export const APP_CONTACT_REQUESTED = 'APP_CONTACT_REQUESTED';
export const APP_CONTACT_SUCCEEDED = 'APP_CONTACT_SUCCEEDED';
export const APP_CONTACT_FAILED = 'APP_CONTACT_FAILED';
