import { MINIMUM_PURCHASE_DELIVERY_FREE } from 'constants/configs';

export const DELIVERY_FREE = 'DELIVERY_FREE';
export const DELIVERY_PAY = 'DELIVERY_PAY';
export const DELIVERY_FREE_PAY = 'DELIVERY_FREE_PAY';
export const DELIVERY_PAY_INTERIOR = 'DELIVERY_PAY_INTERIOR';

export const DELIVERY_FREE_MESSAGE = 'A confirmar';
export const DELIVERY_PAY_MESSAGE = 'A abonar contra entrega';
export const NO_DELIVERY_METHOD_MESSAGE = 'No seleccionó un método de envío';

export const getShippingText = (deliveryMethod, subtotal, envios) => {
  const envio = envios.find(envio => envio.id.toString() === deliveryMethod);
  
  if(!deliveryMethod){
    return 'No seleccionó un método de envío'
  }
  if(envio.arrange_with_seller){
    return 'Arreglar con el vendedor'
  }
  if(envio.monto_minimo && subtotal > envio.monto_minimo){
    return 'Envio Gratis';
  }else{
    return '$' + envio.monto;
  }
  /*
  switch (deliveryMethod) {
    case DELIVERY_FREE:
      return DELIVERY_FREE_MESSAGE;
    case DELIVERY_PAY:
      return DELIVERY_PAY_MESSAGE;
    case DELIVERY_PAY_INTERIOR:
      return DELIVERY_PAY_MESSAGE;
    case DELIVERY_FREE_PAY:
      if (subtotal >= MINIMUM_PURCHASE_DELIVERY_FREE) {
        return DELIVERY_FREE_MESSAGE;
      }
      return DELIVERY_PAY_MESSAGE;
    default:
      return NO_DELIVERY_METHOD_MESSAGE;
  }*/
};
