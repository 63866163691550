import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import useWindowSize from 'hooks/useWindowSize';
import {
  categoriesArrayPropTypes, categoryPropTypes, productsArrayPropTypes,
} from 'helpers/propTypes';
import { categorySelector } from 'redux/selectors/category.selector';

import ItemContainer from 'containers/ItemContainer';
import Products from 'views/Products';

import 'react-tooltip/dist/react-tooltip.css';

const limit = 50;

const orderOptions = [
  { value: 'predeterminado', label: 'Predeterminado' },
  { value: 'ascendente', label: 'Precio: más bajo a más alto' },
  { value: 'descendente', label: 'Precio: más alto a más bajo' },
  { value: 'A-Z', label: 'A-Z' },
  { value: 'Z-A', label: 'Z-A' },
];

const PaginatorContainer = (props) => {
  const {
    active, category, categories, itemsOffers, itemsState, loading, maxPrice,
    minimumPurchase, name, products, url,
  } = props;
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const priceRange = [0, Math.ceil(maxPrice)];
  const [inputRangeValue, setInputRangeValue] = useState(priceRange);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [orderProducts, setOrderProducts] = useState(orderOptions[0]);
  const [productsLoading, setProductsLoading] = useState(true);
  const size = useWindowSize();
  const renderScrollRef = useRef(null);
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const setCurrentPage = (currentPage, currentItems) => {
    const totalItems = Math.ceil(currentItems.length / limit);
    const offset = Math.ceil((currentPage - 1) * limit);
    const itemsPaginated = currentItems.slice(offset, offset + limit);

    // scrollTop();
    setPage(currentPage);
    setTotal(totalItems);
    setItems(itemsPaginated);
  };

  const orderFilteredProducts = (productsToFilter, sortedProducts) => {
    if (sortedProducts) {
      switch (sortedProducts.value) {
        case 'A-Z':
          productsToFilter.sort((product, otherProduct) => {
            const fa = product.name.toLowerCase();
            const fb = otherProduct.name.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          return productsToFilter;
        case 'Z-A':
          productsToFilter.sort((product, otherProduct) => {
            const fa = product.name.toLowerCase();
            const fb = otherProduct.name.toLowerCase();
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          });
          return productsToFilter;
        case 'ascendente':
          productsToFilter.sort((product, otherProduct) => product.price - otherProduct.price);
          return productsToFilter;
        case 'descendente':
          productsToFilter.sort((product, otherProduct) => otherProduct.price - product.price);
          return productsToFilter;
        default:
          return productsToFilter;
      }
    }
    return productsToFilter;
  };

  const filterByPrice = (product, value) => {
    const min = value[0];
    const max = value[1] === 0 ? Math.ceil(maxPrice) : value[1];
    return product.sale
      ? product.sale >= min && product.sale <= max
      : product.price >= min && product.price <= max;
  };

  const filterByCategories = (product) => (
    !category || product.categories.some((cat) => cat.code === category.code)
  );

  const filterItems = () => {
    const filtered = products.filter((product) => (
      filterByPrice(product, inputRangeValue) && filterByCategories(product)
    ));
    const productsFiltered = orderFilteredProducts(filtered, orderProducts);
    setItemsFiltered(productsFiltered);
    setCurrentPage(1, productsFiltered);
  };

  const loadMoreItems = () => {
    if (itemsFiltered.length > limit * page ) {
      delay(2000); 
      setPage(prevPage => prevPage + 1);
      setItems(itemsFiltered.slice(0, (page + 1) * limit));
    }
  };

  useEffect(() => {
    if (itemsState.length > 0 && maxPrice !== 0) {
      setInputRangeValue(priceRange);
      filterItems();
      setProductsLoading(false);
    }
    // eslint-disable-next-line
  }, [category, categories, loading, maxPrice, products]);


  useEffect(() => {
    const handleScroll = () => {
      if (renderScrollRef.current) {
        const scrollPosition = window.scrollY + window.innerHeight;
        const renderScrollPosition = renderScrollRef.current.getBoundingClientRect().top + window.scrollY;

        if (scrollPosition >= renderScrollPosition - 40 && scrollPosition < renderScrollPosition + 150) {
          loadMoreItems();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [itemsFiltered, page]);

  const handlePaginationChange = (event, currentPage) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setCurrentPage(currentPage, itemsFiltered);
  };

  const handleInputRangeChange = (event, value) => {
    setInputRangeValue(value);
    const filtered = products.filter((product) => (
      filterByPrice(product, value) && filterByCategories(product)
    ));
    const productsFiltered = orderFilteredProducts(filtered, orderProducts);
    setItemsFiltered(productsFiltered);
    setCurrentPage(1, productsFiltered);
  };

  const handleSelectSortedProducts = (value) => {
    setOrderProducts(value);

    const filtered = products.filter((product) => (
      filterByPrice(product, inputRangeValue) && filterByCategories(product)
    ));
    const productsFiltered = orderFilteredProducts(filtered, value);
    setItemsFiltered(productsFiltered);
    setCurrentPage(1, productsFiltered);
  };

  const handleRangeChange = (event, value) => {
    setInputRangeValue(value);
  };

  const renderItem = (item) => (
    <div className="margin-product" id={`item-${item.id}`} style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
      {item.stock_product_descriptions.length > 0 && size.width > 500 && (
        <div className="tooltip-item">
          <ReactTooltip
            className="tooltipBorder"
            anchorId={`item-${item.id}`}
            place="bottom"
            variant="light"
          >
            {item.stock_product_descriptions.map((description) => description.stock > 0 && (
              <li className="float-xs-left-v2" key={`${item.id}-${description.stock_description_id}`}>
                {description.stock_descriptions.rgb ? (
                  <div className="input-color">
                    <span className="color" style={{ backgroundColor: description.stock_descriptions.rgb }} />
                  </div>
                ) : (
                  <div className="input-name">
                    <span className="name">{description.stock_descriptions.name}</span>
                  </div>
                )}
              </li>
            ))}
          </ReactTooltip>
        </div>
      )}
    </div>
  );

  const renderItemCarousel = (item) => (
    <div className="col-product-carrousel" id={`item-${item.id}`} key={`item-${item.id}`}>
      <ItemContainer item={item} />
      {item.stock_product_descriptions.length > 0 && size.width > 500 && (
        <div className="tooltip-item">
          <ReactTooltip
            className="tooltipBorder"
            anchorId={`item-${item.id}`}
            place="bottom"
            variant="light"
          >
            {item.stock_product_descriptions.map((description) => description.stock > 0 && (
              <li className="float-xs-left-v2" key={`${item.id}-${description.stock_description_id}`}>
                {description.stock_descriptions.rgb ? (
                  <div className="input-color">
                    <span className="color" style={{ backgroundColor: description.stock_descriptions.rgb }} />
                  </div>
                ) : (
                  <div className="input-name">
                    <span className="name">{description.stock_descriptions.name}</span>
                  </div>
                )}
              </li>
            ))}
          </ReactTooltip>
        </div>
      )}
    </div>
  );

  return (
    <Products
      active={active}
      category={category}
      categories={categories}
      handleInputRangeChange={handleInputRangeChange}
      handleRangeChange={handleRangeChange}
      handlePaginationChange={handlePaginationChange}
      handleSelectSortedProducts={handleSelectSortedProducts}
      inputRangeValue={inputRangeValue}
      orderOptions={orderOptions}
      orderProducts={orderProducts}
      productsOffers={itemsOffers}
      items={items}
      limit={limit}
      loading={loading}
      maxPrice={maxPrice}
      minimumPurchase={minimumPurchase}
      name={name}
      page={page}
      productsLoading={productsLoading}
      renderItem={renderItem}
      renderItemCarousel={renderItemCarousel}
      total={total}
      url={url}
      renderScrollRef = {renderScrollRef}
    />
  );
};

const mapStateToProps = (state, props) => ({
  active: state.modal.active,
  category: categorySelector(state, props),
  categories: state.category.items,
  itemsOffers: state.product.offers,
  itemsState: state.product.items,
  loading: state.product.loading,
  maxPrice: state.product.maxPrice,
  minimumPurchase: state.app.minimumPurchase,
  offers: state.product.offers,
});

PaginatorContainer.defaultProps = {
  active: null,
  category: null,
};

PaginatorContainer.propTypes = {
  active: PropTypes.string,
  category: categoryPropTypes,
  categories: categoriesArrayPropTypes.isRequired,
  itemsOffers: productsArrayPropTypes.isRequired,
  itemsState: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  maxPrice: PropTypes.number.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  products: productsArrayPropTypes.isRequired,
  url: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(PaginatorContainer);
