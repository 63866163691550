import React from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';

import './CartSummary.scss';
import { getLocalStorage } from 'helpers/storage';

const CartSummary = (props) => {
  const {
    items, shippingText, subtotal, coupon,subTotEnv
  } = props;

  const discount = getLocalStorage('discount');
  const subtotalValue = parseFloat(subtotal);
  const percentageOrAmount = coupon?.percentage
    ? (coupon.percentage / 100) * subtotal : (coupon?.amount || 0);
  const totalValueWithDiscount = (discount - percentageOrAmount) > 0
    ? discount - percentageOrAmount : 0;
  const totalValueWithoutDiscount = (subtotal - percentageOrAmount) > 0
    ? subtotal - percentageOrAmount : 0;
  const totalValueWithPercetage = subtotal - percentageOrAmount;
  const dots = (word) => (word.length > 45 ? `${word.substring(0, 45)}...` : word);

  return (
    <div className="container" data-testid="cart-summary-component">
      <div className="row">
        <div className="form-container">
          <h4 className="cart-summary-title">Resumen del pedido</h4>
          <div className="separator-v2" />
          <div className="summary-items">
            {items.map(({
              item, quantity, descriptions, message,
            }) => (
              <div key={item.id}>
                <div className="d-flex flex-row cart-summary-container">
                  <div className="summary-item-image">
                    <img src={item.image1} alt={item.name} />
                  </div>
                  <div className="summary-item-info">
                    <ul>
                      <li className="d-flex flex-row">
                        <span className="summary-item-title">
                          {dots(item.name)}
                        </span>
                      </li>
                      {!!descriptions && (
                        descriptions.map((description) => (
                          <>
                            <li>
                              <span className="cart-item-description">
                                {' '}
                                OPCION:
                                {' '}
                                {description.stock_descriptions.name}
                                {' '}
                                -
                                {' '}
                                {description.quantity}
                              </span>
                            </li>
                          </>
                        ))
                      )}
                      <li>
                        <span className="summary-item-title-v2">
                          Cantidad:
                          {' '}
                          <span className="summary-item-price">{quantity}</span>
                        </span>
                        <span className="summary-item-title-v2">
                          Precio:
                          <span className="summary-item-price">
                            {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                            {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}

                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="summary-item-title-v2">
                          {`Mensaje: ${message ?? ''}`}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="separator" />
              </div>
            ))}
          </div>
          <ul className="summary-subtotal">
            <li>
              <span className="span-subtotal">Subtotal:</span>
              {discount === null
                ? (
                  <span className="float-right span-subtotal">
                    {`$${subtotalValue.toFixed(2)}`}
                  </span>
                )
                : (
                  <del className="float-right span-subtotal">
                    {`$${subtotalValue.toFixed(2)}`}
                  </del>
                )}
            </li>
            {
              coupon && (
                <li>
                  <span className="span-descuentos descuento-automatico">
                    Cupón
                    {' '}
                    {coupon.name}
                    {' '}
                    {
                      coupon.percentage && (
                        `%${coupon.percentage}`
                      )
                    }
                  </span>
                  <span className="float-right span-subtotal">
                    {
                      !coupon.percentage ? (
                        `-$${coupon.amount}`
                      ) : (
                        `-$${subtotalValue * (coupon.percentage / 100)}`
                      )
                    }
                  </span>
                </li>
              )
            }
            {
              (!(coupon?.percentage) && discount) && (
              <li>
                <span className="span-descuentos descuento-automatico">
                  {' '}
                  Descuento automático de
                  {' '}
                  {getLocalStorage('percentage')}
                  %

                </span>
                <span className="float-right span-subtotal discount-span">
                  -$
                  {(subtotalValue - discount).toLocaleString('de-DE')}
                </span>
              </li>
              )
            }
            <li className="span-costo descuento-automatico">
              Costo de envío:
              <span className="float-right span-descuentos descuento-automatico">
                {shippingText}
              </span>
            </li>
            <div className="separator" />
            <li>
              <span className="cart-checkout-title">Total:</span>
              {
                discount === null
                  ? (
                    <span className="float-right cart-checkout-price">
                      {`$${(totalValueWithoutDiscount + subTotEnv).toFixed(2) }`}
                    </span>
                  )
                  : (
                    <span className="float-right cart-checkout-price">
                      {
                         coupon?.percentage ? (
                          `$${(totalValueWithPercetage + subTotEnv).toFixed(2)}`
                        ) : (
                          `$${(totalValueWithDiscount + subTotEnv).toFixed(2)}`
                        )
                      }
                    </span>
                  )
              }

            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

CartSummary.defaultProps = {
  coupon: null,
};

CartSummary.propTypes = {
  items: cartItemsArrayPropTypes.isRequired,
  shippingText: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default CartSummary;
