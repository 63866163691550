import * as constants from 'redux/constants/user.constants';

export const login = (formData) => ({
  type: constants.USER_LOGIN_REQUESTED,
  formData,
});

export const logout = () => ({
  type: constants.USER_LOGOUT_REQUESTED,
});

export const register = (formData) => ({
  type: constants.USER_REGISTER_REQUESTED,
  formData,
});

export const verifyEmail = (formData) => ({
  type: constants.USER_VERIFY_EMAIL_REQUESTED,
  formData,
});

export const resetPassword = (formData) => ({
  type: constants.USER_RESET_PASSWORD_REQUESTED,
  formData,
});

export const contact = (formData) => ({
  type: constants.USER_CONTACT_REQUESTED,
  formData,
});

export const updateProfile = (formData) => ({
  type: constants.USER_UPDATE_PROFILE_REQUESTED,
  formData,
});

export const changePassword = (formData) => ({
  type: constants.USER_CHANGE_PASSWORD_REQUESTED,
  formData,
});

export const getPurchaseHistory = () => ({
  type: constants.USER_GET_PURCHASE_HISTORY_REQUESTED,
});

export const downloadOrderPDF = (orderId) => ({
  type: constants.USER_GET_ORDER_PDF_REQUESTED,
  orderId,
});

export const resetPasswordMail = (formData) => ({
  type: constants.USER_CHANGE_PASSWORD_EMAIL_REQUESTED,
  formData,
});
